import P1 from '@/images/gallery/1.jpg';
import P2 from '@/images/gallery/2.jpg';
import P3 from '@/images/gallery/3.jpg';
import P4 from '@/images/gallery/4.jpg';
import P5 from '@/images/gallery/5.jpg';
import P6 from '@/images/gallery/6.jpg';
import P7 from '@/images/gallery/7.jpg';
import P8 from '@/images/gallery/8.jpg';
import P9 from '@/images/gallery/9.jpg';
import P10 from '@/images/gallery/10.jpg';
import P11 from '@/images/gallery/11.jpg';
import P12 from '@/images/gallery/12.jpg';
import P13 from '@/images/gallery/13.jpg';
import P14 from '@/images/gallery/14.jpg';
import P15 from '@/images/gallery/15.jpg';
import P16 from '@/images/gallery/16.jpg';
import P17 from '@/images/gallery/17.jpg';
import P18 from '@/images/gallery/18.jpg';
import P19 from '@/images/gallery/19.jpg';
import P20 from '@/images/gallery/20.jpg';
import P21 from '@/images/gallery/21.jpg';

export const photos = [
  {
    original: P1,
    // thumbnail: P1,
  },
  {
    original: P2,
    // thumbnail: P2,
  },
  {
    original: P3,
    // thumbnail: P3,
  },
  {
    original: P4,
    // thumbnail: P4,
  },
  {
    original: P5,
    // thumbnail: P5,
  },
  {
    original: P6,
    // thumbnail: P6,
  },
  {
    original: P7,
    // thumbnail: P7,
  },
  {
    original: P8,
    // thumbnail: P8,
  },
  {
    original: P9,
    // thumbnail: P8,
  },
  {
    original: P10,
    // thumbnail: P8,
  },
  {
    original: P11,
    // thumbnail: P8,
  },
  {
    original: P12,
    // thumbnail: P8,
  },
  {
    original: P13,
    // thumbnail: P8,
  },
  {
    original: P14,
    // thumbnail: P8,
  },
  {
    original: P15,
    // thumbnail: P8,
  },
  {
    original: P16,
    // thumbnail: P8,
  },
  {
    original: P17,
    // thumbnail: P8,
  },
  {
    original: P18,
    // thumbnail: P8,
  },
  {
    original: P19,
    // thumbnail: P8,
  },
  {
    original: P20,
    // thumbnail: P8,
  },
  {
    original: P21,
    // thumbnail: P8,
  },
];
