import React from 'react';
import { string } from 'prop-types';
import { Box, Heading, Image, Text, Center } from '@chakra-ui/react';
import { FiMapPin } from 'react-icons/fi';

import ViewLocationButton from '@/components/Common/Buttons/ViewLocation';
import WithAnimation from '@/components/Common/WithAnimation';

import { IMG_FRAME } from '@/constants/assets';
import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';

function WeddingCard(props) {
  const { title, place, placeRoad, date, time, dressCode, urlMaps, note } = props;

  return (
    <Box pos="relative" maxW="310px" margin="0 auto" height="400px">
      <Image pos="absolute" top="0" src={IMG_FRAME.default} />
      <WithAnimation>
        <Heading {...TEXT_PROPS.title} dangerouslySetInnerHTML={{ __html: title }} />
      </WithAnimation>
      <Box maxW="200px" margin="0 auto">
        <WithAnimation>
          <Text
            {...TEXT_PROPS.body}
            dangerouslySetInnerHTML={{ __html: date.replace(',', ',<br />') }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text {...TEXT_PROPS.body}>{time}</Text>
        </WithAnimation>
        {note && (
          <WithAnimation>
            <Text {...TEXT_PROPS.body}>*) {note}</Text>
          </WithAnimation>
        )}
        {place && (
          <WithAnimation>
            <Text
              {...TEXT_PROPS.body}
              fontWeight="bold"
              mt="16px"
              dangerouslySetInnerHTML={{ __html: place }}
            />
          </WithAnimation>
        )}
        {placeRoad && (
          <WithAnimation>
            <Text {...TEXT_PROPS.body} dangerouslySetInnerHTML={{ __html: placeRoad }} />{' '}
          </WithAnimation>
        )}
        {dressCode && (
          <WithAnimation>
            <Text
              {...TEXT_PROPS.body}
              mt="16px"
              dangerouslySetInnerHTML={{ __html: `<b>Dresscode:</b> <br /> ${dressCode}` }}
            />
          </WithAnimation>
        )}
        {urlMaps && (
          <Center mt="16px">
            <WithAnimation>
              <ViewLocationButton
                {...DEFAULT_PROPS.button}
                leftIcon={<FiMapPin />}
                size="sm"
                margin="0 auto"
                href={urlMaps}
                text={'View Location'}
              />
            </WithAnimation>
          </Center>
        )}
      </Box>
    </Box>
  );
}

WeddingCard.propTypes = {
  title: string,
  dressCode: string,
  date: string,
  time: string,
  note: string,
  place: string,
  placeRoad: string,
  urlMaps: string,
};

WeddingCard.defaultProps = {
  dressCode: '',
  title: '',
  date: '',
  note: '',
  time: '',
  place: '',
  placeRoad: '',
  urlMaps: '',
};

export default React.memo(WeddingCard);
