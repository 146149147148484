import React from 'react';
import { bool, func } from 'prop-types';
import loadable from '@loadable/component';

import txtWording from './locales';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
  Image,
} from '@chakra-ui/react';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import Wave from '@/components/Common/Wave';
import GalleryCover from '@/images/gallery/20.jpg';
import useLang from '@/hooks/useLang';

import { photos } from './api/gallery';
import { THE_BRIDE, WEDDING_HASHTAG } from '@/constants';
import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';
import { IMG_FLOWER } from '@/constants/assets';

function PhotoContent({ isOpen, onClose }) {
  const lang = useLang();

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bgColor="bgPrimary" maxW="500px" pos="relative">
        <DrawerBody h="100%" width="100%" padding="0" pos="relative">
          <Box pos="relative" zIndex={2}>
            <Box
              bgImage={`url(${GalleryCover})`}
              height="450px"
              bgSize="cover"
              bgPosition="top center"
            >
              <Box paddingTop="320px">
                <Text {...TEXT_PROPS.title}>{THE_BRIDE}</Text>
              </Box>
            </Box>
            <Wave />
            <Text {...TEXT_PROPS.sub_title} fontSize="24px">
              {WEDDING_HASHTAG}
            </Text>
            {/* Images Cover */}
            <Box padding="0 32px 32px 32px" textAlign="center" color="black">
              {/* 2 Section */}
              <Box paddingTop="12px">
                <Box marginTop="24px">{isOpen && <LoadableGallerry data={photos} />}</Box>
              </Box>
            </Box>
            <Center padding="16px 0 80px 0">
              <Button {...DEFAULT_PROPS.button} onClick={() => onClose()}>
                {txtWording.backContent[lang]}
              </Button>
            </Center>
          </Box>
          <Image
            src={IMG_FLOWER.left}
            transform="rotate(232deg)"
            opacity={0.1}
            pos="absolute"
            bottom="0"
            mb="-300px"
            left="-220px"
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
