import React from 'react';
import { string, object } from 'prop-types';
import { Center, Box, Image } from '@chakra-ui/react';

import { IMG_FRAME } from '@/constants/assets';

function CoupleImage(props) {
  const { imgUrl, imgUrlProps } = props;

  return (
    <Box pos="relative">
      <Center>
        <Image
          src={IMG_FRAME.default}
          height="auto"
          width="280px"
          objectFit="cover"
          objectPosition="center"
          borderRadius="16px"
          marginBottom="16px"
          zIndex="2"
        />
      </Center>
      <Box pos="absolute" w="100%" top="32px" zIndex="1">
        <Center>
          <Image
            src={imgUrl}
            height="300px"
            width="210px"
            objectFit="cover"
            objectPosition="center"
            borderRadius="16px"
            marginBottom="16px"
            {...imgUrlProps}
          />
        </Center>
      </Box>
    </Box>
  );
}

CoupleImage.propTypes = {
  imgUrl: string,
  imgUrlProps: object,
};

CoupleImage.defaultProps = {
  imgUrl: '',
  imgUrlProps: {},
};

export default CoupleImage;
