import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { number, string } from 'prop-types';

import { IMG_FRAME } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';

function CountItem({ number, text }) {
  return (
    <Box
      mb="32px"
      pos="relative"
      width="110px"
      height="130px"
      borderRadius="8px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image pos="absolute" top="0" src={IMG_FRAME.countdown} alt="" />
      <Box mt="42px">
        <Text {...TEXT_PROPS.sub_title} fontSize="3rem" lineHeight="1" color="secondaryColorText">
          {number}
        </Text>
        <Text {...TEXT_PROPS.body} color="secondaryColorText">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
