import React from 'react';
import { object, string } from 'prop-types';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import CoupleImage from './CoupleImage';

import useLang from '@/hooks/useLang';
import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';

function DetailCouple(props) {
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    shortNameProps,
    instagramIdProps,
    imgUrlProps,
    parentNameProps,
    // shortName,
    // fullNameProps,
  } = props;

  const lang = useLang();

  return (
    <Box pos="relative" zIndex={10}>
      <WithAnimation>
        <Center>
          <CoupleImage imgUrl={imgUrl} imgUrlProps={imgUrlProps} />
        </Center>
      </WithAnimation>
      <WithAnimation>
        <Heading {...TEXT_PROPS.title} {...shortNameProps}>
          {fullName}
        </Heading>
      </WithAnimation>
      {/* @TODO: Setup fullname for basis template */}
      {/* <WithAnimation>
        <Text
          color="mainColorText"
          fontSize="xl"
          textAlign="center"
          margin="4px 0"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </WithAnimation> */}
      <WithAnimation>
        <Text
          {...TEXT_PROPS.body}
          {...parentNameProps}
          fontSize="xl"
          lineHeight="1.3"
          dangerouslySetInnerHTML={{ __html: parentName[lang] }}
        />
      </WithAnimation>
      <Center marginTop="24px">
        <WithAnimation>
          <InstagramButton
            id={instagramId}
            text={instagramId}
            onlyIcon={false}
            size="md"
            {...DEFAULT_PROPS.button}
            {...instagramIdProps}
          />
        </WithAnimation>
      </Center>
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
};

export default DetailCouple;
