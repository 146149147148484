import React from 'react';
import { string } from 'prop-types';
import { Grid, GridItem, Box } from '@chakra-ui/react';

import useDateCountdown from '@/hooks/useDateCountdown';
import WithAnimation from '@/components/Common/WithAnimation';
import CountItem from './CountItem';

import { txtDay, txtHour, txtMinutes, txtSeconds } from './locales';

function CountContainer({ lang }) {
  const { days, hours, minutes, seconds } = useDateCountdown();

  return (
    <Box padding="16px 0">
      <Box padding="0 32px">
        <Grid gap={4} templateColumns="repeat(2, 1fr)">
          <GridItem>
            <WithAnimation left>
              <CountItem text={txtDay[lang]} number={days} />
            </WithAnimation>
          </GridItem>
          <GridItem>
            <WithAnimation right>
              <CountItem text={txtHour[lang]} number={hours} />
            </WithAnimation>
          </GridItem>
          <GridItem>
            <WithAnimation left>
              <CountItem text={txtMinutes[lang]} number={minutes} />
            </WithAnimation>
          </GridItem>
          <GridItem>
            <WithAnimation right>
              <CountItem text={txtSeconds[lang]} number={seconds} />
            </WithAnimation>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}

CountContainer.propTypes = {
  lang: string,
};

CountContainer.defaultProps = {
  lang: 'id',
};

export default React.memo(CountContainer);
