import get from '@invitato/helpers/dist/getLocale';

export default {
  akadGeneral: get('Pemberkatan', 'Holy Matrimony'),
  akadMuslim: get('Akad Nikah', 'Akad'),

  descAkadGeneral: get(
    'BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, PEMBERKATAN AKAN DISELENGGARAKAN TERBATAS PADA:',
    'DUE TO RESTRICTIONS ON GATHERING SIZES, THE HOLY MATRIMONY WILL BE HELD WITH A LIMITED CAPACITY ON:',
  ),
  descAkadMuslim: get(
    'BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, AKAD NIKAH AKAN DISELENGGARAKAN TERBATAS PADA:',
    'DUE TO RESTRICTIONS ON GATHERING SIZES, THE AKAD WILL BE HELD WITH A LIMITED CAPACITY ON:',
  ),

  descAkadInvitationMuslim: get(
    'BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, AKAD NIKAH AKAN DISELENGGARAKAN TERBATAS PADA:',
    'WE WILL TIE THE KNOT! PLEASE WITNESS OUR WEDDING VOWS ON:',
  ),
  descAkadInvitationGeneral: get(
    'BERKAITAN DENGAN PEMBATASAN JUMLAH TAMU UNDANGAN, PEMBERKATAN AKAN DISELENGGARAKAN TERBATAS PADA:',
    'WE WILL TIE THE KNOT! PLEASE WITNESS OUR WEDDING VOWS ON:',
  ),

  reception: get('Resepsi', 'Reception'),
  descReception: get(
    'KAMI BERMAKSUD MENGUNDANG BAPAK/IBU/SAUDARA/I DALAM ACARA RESEPSI PERNIKAHAN KAMI YANG AKAN DISELENGGARAKAN PADA:',
    "LET'S CELEBRATE AND ENJOY OUR FIRST DAY AS A HUSBAND AND WIFE AT A WEDDING RECEPTION THAT WILL BE HELD ON:",
  ),

  location: get('Lihat Lokasi', 'View Location'),
};
