import React from 'react';
import { Box, Heading, Image, Text } from '@chakra-ui/react';

import { useGuest } from '@/context/guest';
import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';
import useShift from '@/hooks/useShift';
import WithAnimation from '@/components/Common/WithAnimation';

import { IMG_FLOWER } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';
import { AKAD, WEDDING } from '@/constants';

import WeddingCard from './DetailCard';
import txtWording from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const { guest } = useGuest();
  const lang = useLang();
  const shiftTime = useShift(guest.shift);
  const isInvitation = useInvitation();

  return (
    <Box bgColor="bgPrimary" padding="32px" pos={'relative'}>
      <Box {...rest} padding="42px 0" width="100%" position="relative">
        <Image
          w="300px"
          src={IMG_FLOWER.left}
          transform="rotate(30deg)"
          opacity={0.1}
          pos="absolute"
          top="200px"
          right="-180px"
        />
        <Text {...TEXT_PROPS.sub_title} fontSize="2xl" mb="24px">
          WHEN & WHERE
        </Text>
        <Box>
          {!isInvitation ? (
            <WeddingCard
              title={txtWording.akadGeneral[lang]}
              place={AKAD.place}
              date={WEDDING.fullDate}
              placeRoad={AKAD.road}
            />
          ) : (
            <>
              <WeddingCard
                note="Please don't be late"
                title={txtWording.akadGeneral[lang]}
                date={AKAD.fullDate}
                time={isInvitation ? AKAD.time : ''}
                place={AKAD.place}
                placeRoad={AKAD.road}
                urlMaps={AKAD.gmaps}
              />
              <Box height="80px" />
              <WeddingCard
                title={txtWording.reception[lang]}
                note="Please don't be late"
                date={WEDDING.fullDate}
                time={shiftTime}
                place={WEDDING.place}
                placeRoad={WEDDING.road}
                urlMaps={WEDDING.gmaps}
              />
              {/* DRESSCODE */}
              <WithAnimation>
                <Box>
                  <Heading {...TEXT_PROPS.title} mt="42px">
                    Dresscode
                  </Heading>
                  <Text {...TEXT_PROPS.body}>{WEDDING.dressCode}</Text>
                  <Text {...TEXT_PROPS.body} fontSize="sm">
                    *) Valid for Holy Matrimony and Reception
                  </Text>
                </Box>
              </WithAnimation>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);
