import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';
import { Box, Text, Center, Heading } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';

import { useGuest } from '@/context/guest';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';

import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';
import { GIRL_NAME, BOY_NAME } from '@/constants';
import { BACKGROUND, IMG_LOGO_WEDDING } from '@/constants/assets';

import { txt } from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const { isLoading } = useGuest();
  const isInvitation = useInvitation();
  const lang = useLang();

  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    setShowTitle(true);
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box minHeight="100vh" backgroundColor="white" pos="relative">
      <Box
        pos="absolute"
        top="0"
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`url(${BACKGROUND.cover})`}
      >
        <Box height="100%" bgColor="bgPrimary" opacity={0.2} />
      </Box>

      {showTitle && (
        <Box>
          <Box pos="absolute" width="100%" top="100px">
            <WithAnimation>
              <Text {...TEXT_PROPS.sub_title} textAlign="center" textTransform="uppercase">
                {isInvitation ? `The Wedding of` : `Wedding Announcement`}
              </Text>
            </WithAnimation>
          </Box>
          {/* ABSOLUTE BOTTOM SECTION COMPONENT */}
          <Box
            bottom="50%"
            position="absolute"
            color="secondaryColorText"
            letterSpacing="1px"
            fontWeight="400"
            fontSize="10px"
            width="100%"
            height="120px"
            padding="0 42px"
          >
            <Box>
              <WithAnimation>
                <Center marginTop="10%">
                  <Image src={IMG_LOGO_WEDDING} maxW="300px" />
                </Center>
              </WithAnimation>
              <WithAnimation>
                <Heading {...TEXT_PROPS.title} mt="32px" lineHeight="0.9">
                  {BOY_NAME} <br /> & {GIRL_NAME}
                </Heading>
              </WithAnimation>
              <WithAnimation>
                <ScrollToDown
                  loaded={loaded}
                  loading={loading}
                  marginTop="32px"
                  size="lg"
                  {...DEFAULT_PROPS.button}
                  text={isInvitation ? txt[lang].openWedding : txt[lang].openAnnouncement}
                  onClick={handleClickDetail}
                />
              </WithAnimation>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
