import React from 'react';
import { Box, Center, Text, Link, Divider, Heading } from '@chakra-ui/react';

import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import CoupleDivider from '../CoupleInfo/CoupleDivider';

import IMG_PATTERN_STUDIO from '@/assets/icons/pattern-s.png';

import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_INVITATO, WEDDING_HASHTAG } from '@/constants';
import { IMG_LOGO_WEDDING, LOGO_INVITATO } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';
import * as F from '@/constants/feature-flags';

import * as T from './types';
import txtWording from './locales';

function FooterSection() {
  const lang = useLang();
  const isInvitation = useInvitation();

  return (
    <Box bgSize="cover" bgColor="bgPrimary" pos="relative">
      <CoupleDivider />
      {/* Title and Desc Section */}
      <Box bgColor="bgPrimary" p="52px 32px" textAlign="center" color="secondaryColorText">
        <WithAnimation>
          <Text p="0 24px" {...TEXT_PROPS.body}>
            {isInvitation
              ? txtWording.closingInvitation[lang]
              : txtWording.closingAnnouncement[lang]}
          </Text>
        </WithAnimation>
        {F.MUSLIM_INVITATION && (
          <WithAnimation>
            <Text {...TEXT_PROPS.body}>Wassalamualaikum Warahmatullahi Wabarakatuh</Text>
          </WithAnimation>
        )}
        <WithAnimation type="zoom">
          <Image maxW="230px" src={IMG_LOGO_WEDDING} margin="32px auto" />
        </WithAnimation>
        <WithAnimation>
          <Text mt="42px" {...TEXT_PROPS.sub_title}>
            OUR SINCERE,
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading {...TEXT_PROPS.title}>{THE_BRIDE}</Heading>
        </WithAnimation>
        <WithAnimation>
          <Text {...TEXT_PROPS.body} fontSize="xl">
            {WEDDING_HASHTAG}
          </Text>
        </WithAnimation>
      </Box>
      {/* ////////////////////////////////// */}
      {/* FOOTER SECTION - COPYRIGHT SECTION */}
      {/* ////////////////////////////////// */}
      <Box bgColor="bgAlternative" padding="24px 24px">
        <Center>
          <Link href={URL_INVITATO} target="_blank">
            <Image src={LOGO_INVITATO} maxWidth="130px" margin="0" />
          </Link>
        </Center>
        <Text color="mainColorText" fontFamily="body" textAlign="center" fontSize="sm">
          Created with Love by Invitato
        </Text>
        {F.ENABLE_COLLABORATION && (
          <Box margin="-4px 0 18px">
            <Center>
              <Divider
                margin="16px 0"
                maxWidth="100px"
                opacity="1"
                height="0.5px"
                bgColor="mainColorText"
                border="none"
              />
            </Center>

            <Box display="flex" justifyContent="center" alignItems="center" gap="16px">
              <Link
                href={`https://www.instagram.com/${F.SELECTED_VENDOR_COLLABORATION.instagram}/`}
                target="_blank"
              >
                <Image
                  src={F.SELECTED_VENDOR_COLLABORATION.imgUrl}
                  {...F.SELECTED_VENDOR_COLLABORATION.imgProps}
                />
              </Link>
              <Link href={`https://www.instagram.com/patternstudio_id/`} target="_blank">
                <Image src={IMG_PATTERN_STUDIO} width="40px" />
              </Link>
            </Box>
            <Center>
              <Text color="mainColorText" align="center" fontSize="sm">
                In Collaboration with {F.SELECTED_VENDOR_COLLABORATION.vendorName} & Pattern Studio
              </Text>
            </Center>
          </Box>
        )}
        <Link {...T.FOOTER_LINK_PROPS} href={SOUND_URL}>
          <Text>{`Song by ${SOUND_BY}`}</Text>
        </Link>
        <Text
          {...T.FOOTER_LINK_PROPS}
        >{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}</Text>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
