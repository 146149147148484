import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Button, Center } from '@chakra-ui/react';

/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loading, onClick, text, style, ...rest }) {
  return (
    <Center style={style}>
      <Button
        colorScheme="blackAlpha"
        size="sm"
        isLoading={loading}
        className="animation-heart"
        fontWeight="normal"
        onClick={onClick}
        _hover={{ bgColor: 'hover' }}
        {...rest}
      >
        {text}
      </Button>
    </Center>
  );
}

ScrollToDown.propTypes = {
  loading: bool.isRequired,
  text: string,
  style: object,
  onClick: func.isRequired,
};

ScrollToDown.defaultProps = {
  text: 'Buka Undangan',
  style: {},
};

export default React.memo(ScrollToDown);
