import React, { useEffect } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

import DetailCouple from './DetailCard';
import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

import {
  BOY_NAME_SHORT,
  BOY_NAME,
  BOY_PARENT_NAME,
  GIRL_NAME_SHORT,
  GIRL_NAME,
  GIRL_PARENT_NAME,
  IG_BOY,
  IG_GIRL,
  IS_BOY_FIRST,
} from '@/constants';
import { IMG_BRIDE, IMG_FLOWER, IMG_LOGO_WEDDING } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';

import WithAnimation from '@/components/Common/WithAnimation';
import CoupleDivider from './CoupleDivider';

const BOY_PROPS = {
  fullName: BOY_NAME,
  shortName: BOY_NAME_SHORT,
  parentName: BOY_PARENT_NAME,
  instagramId: IG_BOY,
  imgUrl: IMG_BRIDE.boy,
};

const GIRL_PROPS = {
  fullName: GIRL_NAME,
  shortName: GIRL_NAME_SHORT,
  parentName: GIRL_PARENT_NAME,
  instagramId: IG_GIRL,
  imgUrl: IMG_BRIDE.girl,
};

function CoupleInfo({ ...rest }) {
  const FIRST_ITEM = IS_BOY_FIRST ? BOY_PROPS : GIRL_PROPS;
  const SECOND_ITEM = IS_BOY_FIRST ? GIRL_PROPS : BOY_PROPS;
  const TITLE = IS_BOY_FIRST ? 'The Groom & Bride' : 'The Bride & Groom';

  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Box pos="relative" pb="0" bgColor="bgPrimary" overflow="hidden" {...rest}>
      <CoupleDivider />
      <WithAnimation type="zoom">
        <Text
          {...TEXT_PROPS.sub_title}
          margin="42px 0"
          lineHeight="1"
          textTransform="uppercase"
          fontSize="2xl"
          dangerouslySetInnerHTML={{ __html: TITLE }}
        />
      </WithAnimation>
      <DetailCouple {...FIRST_ITEM} />
      <Box pos="relative" minH="400px" paddingBottom="52px">
        <WithAnimation type="zoom">
          <Image w="120px" src={IMG_LOGO_WEDDING} margin="52px auto" />
        </WithAnimation>
        <DetailCouple {...SECOND_ITEM} />
        <Image
          w="300px"
          src={IMG_FLOWER.left}
          transform="rotate(30deg)"
          opacity={0.1}
          pos="absolute"
          top="-32px"
          right="-150px"
        />
        <Image
          src={IMG_FLOWER.left}
          transform="rotate(232deg)"
          opacity={0.1}
          pos="absolute"
          bottom="0"
          mb="-90px"
          left="-220px"
        />
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);
