import React from 'react';
import { Box, Heading, Image, Text } from '@chakra-ui/react';

import { useGuest } from '@/context/guest';
import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE_FULL, AYAT } from '@/constants';
import { BACKGROUND, IMG_CASTEL } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';
import txtWording from './locales';

function WelcomeV4() {
  const lang = useLang();
  const isInvitation = useInvitation();

  const { guest } = useGuest();
  const { name } = guest;

  return (
    <Box pos="relative" bgColor="bgPrimary" bgImage={BACKGROUND.batik} bgSize="contain">
      <Box padding="62px 32px">
        <WithAnimation>
          <Box textAlign="center" marginBottom="52px">
            <Text {...TEXT_PROPS.sub_title} fontSize="xl" letterSpacing="0">
              {txtWording.textDear[lang]}
            </Text>
            <Text {...TEXT_PROPS.sub_title} fontSize="xl">
              {name ? name : txtWording.guest[lang]}
            </Text>
          </Box>
        </WithAnimation>
        <Box pos="relative">
          <WithAnimation>
            <Text {...TEXT_PROPS.body}>
              {/* @TODO: for basis template */}
              {/* NEED TO replace with invitato common wording */}
              {isInvitation
                ? `By the grace of GOD and the blessings of our family, we cordially request the honour of
            your presence, at the wedding of`
                : txtWording.welcomingAnnouncement[lang]}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Box margin="24px 0">
              <Heading
                {...TEXT_PROPS.title}
                fontSize="48px"
                lineHeight="1"
                dangerouslySetInnerHTML={{ __html: THE_BRIDE_FULL.replace('&', '<br />&') }}
              />
            </Box>
          </WithAnimation>
          <WithAnimation>
            <div>
              <Text {...TEXT_PROPS.body}>{AYAT.text}</Text>
              <Text {...TEXT_PROPS.body}>{AYAT.verse}</Text>
            </div>
          </WithAnimation>
        </Box>
        <Box height="160px" />
      </Box>
      <Box pos="absolute" bottom="0" height="200px" width="100%">
        <WithAnimation>
          <Image src={IMG_CASTEL} alt="" height="200px" width="auto" margin="0 auto" />
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);
