import React from 'react';
import { Box, Image } from '@chakra-ui/react';

import { BACKGROUND, IMG_FLOWER } from '@/constants/assets';
import WithAnimation from '@/components/Common/WithAnimation';

function CoupleDivider() {
  return (
    <Box>
      <Box
        pos="relative"
        bgImage={BACKGROUND.batikLandscape}
        bgSize="contain"
        backgroundRepeat="repeat-x"
        height="80px"
      />
      <Box w="100px" pos="absolute" mt="-36px" left="-42px">
        <WithAnimation type="zoom">
          <Image src={IMG_FLOWER.left} />
        </WithAnimation>
      </Box>
      <Box w="120px" pos="absolute" mt="-36px" right="-48px">
        <WithAnimation type="zoom" delay={500}>
          <Image src={IMG_FLOWER.right} />
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(CoupleDivider);
