import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Heading } from '@chakra-ui/react';

import CountDown from '@/components/Common/CountDown';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import useInvitation from '@/hooks/useInvitation';

import { BACKGROUND } from '@/constants/assets';
import { DEFAULT_PROPS, TEXT_PROPS } from '@/constants/default-props';
import txtWording from './locales';

function CountingDown({ lang }) {
  const isInvitation = useInvitation();

  return (
    <Box
      zIndex="3"
      position="relative"
      bgImage={`url(${BACKGROUND.countdown})`}
      bgSize="cover"
      bgPosition="top"
    >
      <Box
        width="100%"
        minHeight="90vh"
        display="flex"
        h="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <WithAnimation type="zoom">
            <Heading {...TEXT_PROPS.title} lineHeight="0.8" color="secondaryColorText">
              Counting the <br /> Days!
            </Heading>
          </WithAnimation>
          <CountDown lang={lang} />
          {isInvitation && (
            <WithAnimation type="zoom">
              <Center>
                <AddToCalendar {...DEFAULT_PROPS.button} text={txtWording.calendar[lang]} />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default React.memo(CountingDown);
