import get from '@invitato/helpers/dist/getLocale';

export default {
  thanks: get(
    'TERIMAKASIH UNTUK SEMUA KATA, <br />DOA, DAN PERHATIAN YANG DIBERIKAN.',
    'THANK YOU FOR ALL THE WORDS, <br />PRAYERS, AND ATTENTION GIVEN',
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Anda akan tetap ada di hati dan pikiran kami, meski kami terpaksa tidak bisa mengundang karena keadaan. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Kami mengharap pengertian Anda atas berlakunya peraturan pembatasan jumlah tamu undangan.',
    `It will be a joy for us if you are still willing to give your blessing from afar. Please know that you are in our thoughts and will be in our hearts, even though we are deeply sorry, we can't invite you to the ceremony. Thank you for all the words, prayers, and attention given.`,
  ),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Kami mengharap pengertian Anda atas semua pemberlakuan protokol kesehatan di hari pernikahan. Sampai jumpa di hari bahagia kami!',
    'It will be a joy for us if you are able to attend our wedding day. Thank you for all the kind words, prayers, and attention given. ',
  ),
};
