import React from 'react';
import { Box, Center, Button, Heading, Text, Image, useDisclosure } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import CoupleImage from '../CoupleInfo/CoupleImage';
import Content from './Content';

import useLang from '@/hooks/useLang';
import { THE_BRIDE, WEDDING_HASHTAG } from '@/constants';
import { IMG_COVER_GALLERY, IMG_FLOWER } from '@/constants/assets';
import { TEXT_PROPS } from '@/constants/default-props';
import { DEFAULT_PROPS } from '@/constants/colors';

import txtWording from './locales';

function PhotoSection() {
  const lang = useLang();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box bgColor="bgPrimary" padding="42px 32px" pos="relative">
      <Image
        src={IMG_FLOWER.left}
        transform="rotate(232deg)"
        opacity={0.1}
        pos="absolute"
        top="100px"
        left="-220px"
      />
      <WithAnimation type="zoom">
        <Box mb="32px">
          <Text {...TEXT_PROPS.sub_title} fontSize="xl">
            POTRAIT OF US
          </Text>
          <Heading {...TEXT_PROPS.title} lineHeight="1.3">
            {THE_BRIDE}
          </Heading>
          <Text {...TEXT_PROPS.body} fontSize="xl">
            {WEDDING_HASHTAG}
          </Text>
        </Box>
      </WithAnimation>
      <WithAnimation type="zoom">
        <Center>
          <CoupleImage imgUrl={IMG_COVER_GALLERY} />
        </Center>
      </WithAnimation>
      {/* Button Section */}
      <WithAnimation type="zoom">
        <Center>
          <Button {...DEFAULT_PROPS.button} mt="24px" onClick={onOpen}>
            {txtWording.btnGallery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      <Content isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export default React.memo(PhotoSection);
