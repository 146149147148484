export const txt = {
  id: {
    openWedding: `Buka Undangan`,
    openAnnouncement: 'Buka Pemberitahuan',
  },
  en: {
    openWedding: `Let's Begin`,
    openAnnouncement: 'Open Announcement',
  },
};
